
/* jobDetails.css */  

.job-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error-message {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 20px;
    padding: 10px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 20px;
}

/* page header */

.jobDetails-home-button {
    margin: 20px 0;
    padding: 8px 16px;
    background-image: linear-gradient(to right, #ead000 0%, #f99d25 100%);
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    border-radius: 5px;
    margin-right: 10px;
}