/* searchbar.css */
.search-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px; /* Adjust based on header size */
}

.box {
    width: 100%; /* Take full width to contain its children properly */
    height: 60px; /* Fixed height for the search bar */
    max-width: 650px; /* Maximum width to prevent it from getting too wide on larger screens */
    padding: 10px; /* Add some padding for better spacing */
    margin: 0; 
    margin-bottom: 10px;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    align-content: center;
    transition: all 0.5s ease;
    border-radius: 50%; /* Rounded shape for the search bar */
}

.search-header {
    padding-bottom: 30px;
    color: #ffd52d;
    font-size: 28px;
    font-weight: bold;
}

.input {
    position: relative;
    padding: 5px;
    /* width: 100%; Full width to fill the parent container */
    min-width: 50px;
    height: 100%;
    background: none;
    border: 4px solid #FFFFFF;
    border-radius: 50px;
    box-sizing: border-box;
    font-family: Helvetica, sans-serif;
    font-size: 26px;
    font-weight: bold;
    /* color: #4c4c4c; */
    outline: none;
    transition: all 0.5s ease, background 0.5s ease, border-radius 0.5s ease;
}

.box:hover .input,
.box:focus-within .input {
    /* width: auto; Desired expanded width */
    height: auto; /* Standard input field height */
    border-radius: 10px; /* Less rounded corners */
    font-size: 26px; /* Show text */
    background: #FFFFFF; /* Background color on expand */
    transition: all 0.5s ease, background 0.5s ease, border-radius 0.5s ease;
}

/* Clear button styles */

.clear-button {
    position: absolute; /* Position absolutely within the .box */
    right: 10px; /* Position to the right inside the input field */
    top: 50%; /* Center vertically */
    transform: translateY(-30%); /* Center vertically accounting for its own height */
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Smooth opacity transition */
    background: transparent;
    border: none;
    color: #FFFFFF;
    font-size: 20px;
    cursor: pointer;
    outline: none; /* Remove focus outline */
}

.box:focus-within .clear-button {
    opacity: 1; /* Make visible when input is focused */
}

.no-results-message {
    margin-top: 10px;
    padding: 10px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    text-align: center;
}

/* Responsive adjustments if needed */
@media screen and (max-width: 768px) {
    .box {
        width: 60px; /* Maintain circle shape on mobile */
        height: 60px;
    }

    .box:hover .input,
    .box:focus-within .input {
        width: 90%; /* Adjust width on smaller screens */
    }
}

/* Results container and card styles */

.results-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.results-container {
    margin: 20px 0;
    width: 100%; /* Full width to align with the card or content area */
    max-height: 35vh; /* Adjust based on your layout, ensures it doesn't take more than 80% of the viewport height */
    overflow-y: auto; /* Enables vertical scrolling */
    padding: 10px;
    border-top: 2px solid #ccc; /* Adds a subtle top border, adjust color as needed */
    background-color: rgba(255, 255, 255, 0.8); /* Light background for the results, adjust opacity as needed */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    border-radius: 10px; /* Rounded corners like your card style */
}

@media screen and (max-width: 768px) {
    .results-container {
        max-height: 60vh; /* Increase the height a bit on smaller screens */
    }
}

@media screen and (max-width: 480px) {
    .results-container {
        max-height: 70vh; /* Further increase the height on very small screens */
    }
}

.search-results {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Align items to start stacking on the left */
    gap: 20px;
    margin-top: 20px; /* Adjust as needed */
}

.search-card {
    margin: 10px;
    padding: 10px;
    background: #FFFFFF; /* Cards background */
    border-radius: 8px; /* Rounded corners for each result card */
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1); /* Consistent shadow with the main card */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transform for hover effects, Consistent transitions for transform and shadow */
}

.search-card:hover {
    transform: translateY(-5px); /* Slight lift effect on hover */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow for better visibility */
}

.search-card h3 {
    margin-bottom: 10px;
    font-size: 18px;
}

.search-card p {
    color: #555555;
}

.details-button-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.details-button {
    position: absolute;
    margin-top: 10px;
    bottom: 10px;
    right: 10px;
    background-color: #1D9D20; /* Adjust background color */
    color: white;
    font-size: 14px;
    font-weight: bold;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
}

.details-button:hover {
    opacity: 0.8;
}

@media screen and (max-width: 768px) {
    .box {
        padding: 5px; /* Reduce padding on smaller screens */
    }

    .input {
        width: 90%; /* Slightly larger on smaller screens */
    }

    .search-header {
        font-size: 20px; /* Even smaller on mobile devices */
    }
}

@media screen and (max-width: 480px) {
    .search-header {
        font-size: 18px; /* Adjust for very small screens */
        padding-bottom: 20px;
    }

    .results-content, .results-container {
        width: 95%; /* Use more of the screen width */
        padding: 5px; /* Reduce padding to save space */
    }

    .search-results {
        gap: 10px; /* Less gap to fit more content */
    }

    .search-card {
        width: 92%; /* Cards take full width to maximize space */
    }

    .details-button {
        font-size: 12px; /* Smaller font size for buttons */
    }
}
