/* DEMO APP Styles */

.app-container {
  display: relative;
  flex-direction: column;
  align-items: center;
}

.header { /* Style the header element */
  width: 100%;
  color: white;
  text-align: center;
  display: flex;
  padding: 48px;
  justify-content: space-between;
  align-items: center;
}

.nav {
  display: flex;
  align-items: center;
}

.demo-header h1 {
  text-align: center;
  margin-top: 35px; /* Adjust spacing as needed */
  color: #FFFFFF;
  font-size: 3em;
}

.demo-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px; /* Adjust spacing as needed */
}

.contact-info {
  margin: 20px 0;
  padding: 20px;
  color: #FFFFFF;
  font-size: 18px;
}

.signup-button {
  padding: 8px 16px;
  background-color: #FFFFFF;
  color: #1D9D20;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  margin-right: 10px;
  transition: opacity 0.3s;
}

a.signup-button {
  color: #1D9D20; /* Green color */
}

.signup-button:hover {
  opacity: 0.8;
}

.login-button {
  padding: 8px 16px;
  background-color: #147916;
  color: #FFFFFF;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  transition: opacity 0.3s;
}

a.login-button {
  color: #FFFFFF; /* White color */
}

.login-button:hover {
  opacity: 0.8;
}


/* GENERAL APP Styles */
.card {
  width: 90%;
  height: 80%;
  margin: auto;
  min-height: 512px;
  box-shadow: 1px 5px 36px 10px rgba(27,196,42,0.75);
  background: linear-gradient(360deg, rgba(1,75,24,1) 0%, rgba(51,121,9,1) 34%, rgba(7,171,39,1) 91%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 512px) {
  .card {
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
  }
}

.logo {
  font-weight: bold;
  font-size: 3.5em;
  transition: opacity 0.05s ease;
  display: inline-flex;
  align-items: center;
}

.logo:hover {
  opacity: 0.75;
}

.logo-image {
  height: 1em;
  width: auto;
  vertical-align: middle;
}

.social {
  display: flex;
}

.social a {
  display: inline-block;
  margin-right: 12px;
  transition: opacity 0.05s ease;
}

.social a:last-child {
  margin-right: 0;
}

.social a:hover {
  opacity: 0.75;
}

.social .icon {
  width: 18px;
  fill: #fff;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content .title-holder {
  color: #fff;
  text-align: center;
  margin-bottom: 24px;
}

.content .title-holder h1 {
  font-weight: bold;
  font-size: 46px;
  margin-bottom: 12px;
}

.content .title-holder p {
  font-size: 26px;
  line-height: 28px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.75);
}

@media screen and (max-width: 768px) {
  .content .title-holder {
    max-width: 80%;
  }
}

.content .cta-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.content .cta {
  /* min-width: 64px;
  margin: 0 12px;
  padding: 16px 24px;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1); */
  /* transition: box-shadow 0.3s, transform 0.1s; */
  display: inline-block;
  margin: 0 12px;
  padding: 15px 30px;
  color: charcoal;
  font-size: 24px;
  font-weight: bold;
  background-image: linear-gradient(to right, #ead000 0%, #f99d25 100%);
  border: none;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.3s ease-out, background-size 0.5s ease; /* Smooth transition for scaling and background */

  /* Initial background size */
  background-size: 150% 150%;
}

.content .cta:hover {
  transform: scale(1.05); /* Scale up on hover */
  background-size: 200% 200%; /* Expand the gradient background */
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 48px;
  justify-content: right;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

a.underlined:hover {
  border-bottom: 1px dotted #fff;
}


/* General Styles for Smaller Screens */
@media screen and (max-width: 768px) {
  .header, .footer {
    padding: 24px;  /* Reduced padding for smaller screens */
  }

  .logo {
    font-size: 2em;  /* Smaller logo font size for mobile */
  }

  .content .title-holder {
    max-width: 90%;  /* Increase width percentage for better fitting */
    padding: 0 10px;  /* Add padding to maintain space on the sides */
  }

  .content .title-holder h1 {
    font-size: 32px;  /* Smaller font size for main title */
  }

  .content .title-holder p {
    font-size: 18px;  /* Smaller font size for paragraph text */
  }

  .content .cta-holder {
    flex-direction: column;  /* Stack buttons vertically on smaller screens */
    margin-top: 10px;
  }

  .content .cta {
    font-size: 18px;  /* Smaller button font size */
    padding: 12px 20px;  /* Adjust padding to maintain proportions */
    margin: 10px 0;  /* Adjust margin for vertical stacking */
  }

  .contact-info {
    font-size: 16px;  /* Smaller text for contact info */
  }
}

/* Responsive adjustments for extra small screens */
@media screen and (max-width: 480px) {
  .header {
    padding: 15px;  /* Even smaller padding for very small screens */
  }

  .logo {
    font-size: 1.8em;  /* Further reduce the logo size */
  }

  .content .title-holder h1 {
    font-size: 28px;  /* Further reduce the title size */
  }

  .content .title-holder p {
    font-size: 16px;  /* Further reduce the paragraph text size */
  }
}

/* Ensuring the card stretches to full width in very small screens */
@media screen and (max-width: 512px) {
  .card {
    width: 100%;
    min-height: auto;  /* Adjust the min-height for mobile devices */
  }
}

